
import '../../App.css';
import '../pages/contact.css'

import React, { useRef } from "react";

import { MdAlternateEmail } from "react-icons/md";
import { AiTwotonePhone } from "react-icons/ai";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    e.target.reset();

    emailjs
      .sendForm(
        "service_8oza0en",
        "template_o7i8u8o",
        form.current,
        "KoioCSc55S_ZpeEk5"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section id="contact">
      
   

      <div className="container contact_container">
        
      <video src='/videos/video-4.mp4' autoPlay loop muted />
        <div className="contact_options">
          <article className="contact-option">
            <MdAlternateEmail className="contact_option-icon" />
            <h4>Email</h4>
            <h5>kandb3mbroidery@gmail.com</h5>
            <a href="kandb3mbroidery@gmail.com" target="blank">
              Send A Message
            </a>
          </article>
          <article className="contact-option">
            <AiTwotonePhone className="contact_option-icon" />
            <h4>Phone</h4>
            <h5>(405)905-1415</h5>
          </article>
        </div>
       
        <form onSubmit={sendEmail} ref={form}>
          <strong className='contact-us' style={{paddingBottom:"15%"}}></strong>
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="enail" name="email" placeholder="Your email" required />
          <textarea
            name="message"
            placeholder="Please Enter A Message"
            required
          />
          <button type="submit" className="btn btn-primary">
            Send us a Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
